import { Badge, BellOutlined, Popover } from 'tera-dls';
import User from './User';
import UserNotification from './UserNotification';
import { useQuery } from '@tanstack/react-query';
import NotificationApi from 'states/api/notification';
import { useMemo } from 'react';

const Header = () => {
  const { data: summaryList } = useQuery(
    ['get-summary-notification-list'],
    () => NotificationApi.getSummary({ params: { isUnread: 1 } }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  console.log('summaryList', summaryList);

  const isNotify = useMemo(() => {
    const total = summaryList?.find(
      (item) => item?.module === 'all',
    )?.total_count;
    return total || 0;
  }, [summaryList]);

  console.log('isNotify', isNotify);

  return (
    <div className="flex gap-2.5 pr-2.5">
      <Popover
        content={<UserNotification />}
        trigger="click"
        placement="bottom-end"
        className="w-[600px] border"
        showArrow={false}
      >
        <div>
          <Badge
            count={isNotify}
            className="w-[15px] h-[15px] bg-red-500 text-white text-[8px] p-0"
          >
            <BellOutlined className="w-6 h-6 cursor-pointer" />
          </Badge>
        </div>
      </Popover>
      <User />
    </div>
  );
};
export default Header;
