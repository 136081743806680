import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate, messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestment from '_common/dof/Select/SelectInvestment';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import useConfirm from '_common/hooks/useConfirm';
import InvestmentApi from 'pages/Invest/Invesment/api';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import CeasesInvestmentApi from '../../api';

interface CeasesInvestmentFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function CeasesInvestmentForm(props: CeasesInvestmentFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const { isDirty } = form.formState;
  const queryClient = useQueryClient();
  const invest_id = form.watch('invest_id');

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-cease-investment-detail', id],
    () => CeasesInvestmentApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: dataDetailInvestment } = useQuery(
    ['get-investment-detail', invest_id],
    () => InvestmentApi.getDetail({ id: invest_id }),
    {
      enabled: !!invest_id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateCeasesInvestment, isLoading: isLoadingMutate } =
    useMutation(
      (params: any) => {
        if (id) return CeasesInvestmentApi.update({ id, params });
        return CeasesInvestmentApi.create({ params });
      },
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            onClose();
            queryClient.invalidateQueries(['get-investment-list']);
            queryClient.invalidateQueries(['get-cease-investment-list']);
            queryClient.invalidateQueries([
              'get-summary-cease-investment-list',
            ]);
            queryClient.invalidateQueries(['get-cease-investment-detail']);
            onSuccess && onSuccess();
          }
        },
        onError: (error: any) => {
          ErrorToast({ errorProp: error?.data });
        },
      },
    );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    if (!dataDetailInvestment) return;

    const data = {
      invest_id: value?.invest_id,
      investor_id: dataDetailInvestment?.investor_id,
      type_status: 'sumi',
    };

    mutateCeasesInvestment(data);
  };

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        invest_id: dataDetails?.invest_id?.id,
        investor: dataDetails?.investor_id?.investor_name,
        invest_model_id: dataDetails?.invest_id?.type,
      };
      setTimeout(() => {
        form.reset(initData);
      }, 100);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (dataDetailInvestment && !id) {
      form.setValue(
        'investor',
        dataDetailInvestment?.investor_id &&
          dataDetailInvestment?.investor?.investor_name,
      );
      form.setValue('invest_model_id', dataDetailInvestment?.type);
    }
  }, [dataDetailInvestment]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={id ? 'Sửa ngưng đầu tư' : 'Thêm ngưng đầu tư'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <FormTeraItem
              label="Đầu tư"
              name="invest_id"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <SelectInvestment
                paramsApi={{
                  include_id: invest_id,
                  isInvested: true,
                  isActive: true,
                  is_pending: 1,
                }}
                allowClear={false}
              />
            </FormTeraItem>
            <FormTeraItem label="Nhà đầu tư" name="investor">
              <Input disabled />
            </FormTeraItem>
            <FormTeraItem label="Danh mục đầu tư" name="invest_model_id">
              <SelectInvestmentCategory disabled />
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default CeasesInvestmentForm;
