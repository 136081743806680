import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import NoData from '_common/component/NoData';
import TabForm from '_common/component/TabForm';
import { IconButton } from '_common/component/TableColumnCustom';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import SelectOrderInspection from '_common/dof/Select/SelectOrderInspection';
import SelectTicketType from '_common/dof/Select/SelectTicketType';
import TableTera from '_common/dof/TableTera';
import UploadFiles from '_common/dof/UploadFiles';
import OrderInspectionApi from 'pages/Sale/OrderInspection/api';
import { ORDER_STATUS_KEY } from 'pages/Sale/OrderInspection/constants';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  CubeOutlined,
  formatCurrency,
  Image,
  InformationCircleOutlined,
  ItemType,
  notification,
  PhotoOutlined,
  PlusOutlined,
  Row,
  Spin,
  XCircleSolid,
} from 'tera-dls';
import SaleOrderReturnApi from '../../api';
import { SALE_ORDER_RETURN_URL } from '../../url';
import { arraysAreEqual } from '_common/utils';
import { usePrompt } from '_common/hooks/usePrompt';

const TABS_FORM = {
  information: {
    title: 'Thông tin chung',
    icon: <InformationCircleOutlined className="w-5 h-5" />,
  },
  product: {
    title: 'Sản phẩm',
    icon: <CubeOutlined className="w-5 h-5" />,
  },
  images: {
    title: 'Hình ảnh trả hàng',
    icon: <PhotoOutlined className="w-5 h-5" />,
  },
};

function SaleOrderReturnForm() {
  const form = useForm({ mode: 'onChange' });
  const navigate = useNavigate();
  const { id } = useParams();
  const tableRef = useRef(null);

  const [dataSource, setDataSource] = useState([]);
  const [dirty, setDirty] = useState<boolean>(false);
  const [fileList, setFileList] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const order_id = form.watch('order_id');
  const isDirty = form.formState?.isDirty;

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-sale-order-return-detail', id],
    () => SaleOrderReturnApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: dataDetailOrder } = useQuery(
    ['get-order-inspection-detail', order_id, id],
    () => OrderInspectionApi.getDetail({ id: order_id }),
    {
      enabled: !!order_id && !id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const ticket_type = id
    ? dataDetails?.ticket_type
    : dataDetailOrder?.ticket_type;

  const { mutate: mutateSaleOrderReturn, isLoading: isLoadingMutate } =
    useMutation(
      (params: any) => {
        if (id) return SaleOrderReturnApi.update({ id, params });
        return SaleOrderReturnApi.create({ params });
      },
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            setDirty(false);
            form.reset();
            queryClient.invalidateQueries(['get-sale-order-return-list']);
            queryClient.invalidateQueries(['get-order-inspection-list']);
            queryClient.invalidateQueries([
              'get-summary-sale-order-return-list',
            ]);
            setTimeout(() => navigate(-1));
          }
        },
        onError: (error: any) => {
          ErrorToast({ errorProp: error?.data });
        },
      },
    );

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(SALE_ORDER_RETURN_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đơn trả hàng bán
        </a>
      ),
    },
    {
      title: !id ? 'Thêm đơn trả hàng bán' : 'Sửa đơn trả hàng bán',
    },
  ];

  const columns = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (name, record) => {
        const image =
          ticket_type === 'order'
            ? record?.file_upload
            : record?.product_image_request;

        return (
          <div className="flex gap-x-2.5">
            <Image
              src={image}
              alt={image}
              containerClassName="w-[30px] h-[30px] rounded overflow-hidden border shrink-0"
              imageClassName="object-cover"
            />
            <p className="line-clamp-2 break-word">{name}</p>
          </div>
        );
      },
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'abbreviation',
      width: 200,
    },
    {
      title: 'Danh mục',
      dataIndex: 'catalog_name',
      width: 150,
      render: (catalog_name) => <p className="line-clamp-2">{catalog_name}</p>,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_name',
      width: 100,
      render: (unit_name) => unit_name,
    },
    {
      title: 'Số lượng thực nhận',
      dataIndex: 'quantity_check',
      width: 150,
    },
    {
      title: 'Số lượng trả hàng',
      dataIndex: 'remaining_quantity',
      width: 150,
      editable: true,
      type: 'int',
      inputProps: (record) => {
        return {
          min: 0,
          max: record?.quantity_check,
          formatter: (value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          parser: (value) => value!.replace(/\$\s?|(,*)/g, ''),
        };
      },
    },
    {
      title: 'Đơn giá',
      dataIndex: 'amount',
      width: 150,
      render: (amount) => formatCurrency(amount),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 200,
      render: (_, record) => {
        const total = record?.amount * record?.remaining_quantity;
        return formatCurrency(total);
      },
    },
  ];

  const convertData = (data: any[]) => {
    const dataConverted = data.map((item) => ({
      investment_product_id: item?.id,
      quantity_return: item?.remaining_quantity,
    }));
    return dataConverted;
  };

  const convertDataTableProduct = (data: any[]) => {
    const products = data?.map((prod) => ({
      id: prod?.investment_product_id,
      name: prod?.product_name,
      abbreviation: prod?.abbreviation,
      catalog_name: prod?.catalog_name,
      unit_name: prod?.unit_name,
      file_upload: prod?.image,
      product_image_request: prod?.product_image_request?.[0],

      amount: prod?.amount,
      quantity_check: prod?.quantity_check,
      remaining_quantity: prod?.quantity_return,
    }));
    return products;
  };

  const handleSubmitForm = (value) => {
    const data = {
      order_id: value?.order_id,
      order_detail: convertData(dataSource),
      feedback_image: fileList,
      note: value?.note,
      type_order_status: 'sumi',
    };

    mutateSaleOrderReturn(data);
  };

  const handleRemoveFile = (url: any) => {
    const files = fileList.filter((item) => item !== url);
    setFileList(files);
  };

  const total = useMemo(() => {
    const sum = dataSource?.reduce((total, current) => {
      return total + current?.amount * current?.remaining_quantity;
    }, 0);
    return sum;
  }, [dataSource]);

  useEffect(() => {
    if (dataDetailOrder && !id) {
      const products = convertDataTableProduct(
        dataDetailOrder?.order_detail?.filter((item) => item?.quantity_check),
      );
      setDataSource(products);
    }
  }, [dataDetailOrder]);

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        order_id: dataDetails?.ref_no,
        investor: dataDetails?.investor_name || 'Hệ thống',
        type: dataDetails?.type,
        device: dataDetails?.device_name,
        invest: dataDetails?.invest_id?.name,
        ticket_type: dataDetails?.ticket_type,
        note: dataDetails?.note,
      };
      Object.entries(initData).forEach(
        ([fieldName, fieldValue]: [any, any]) => {
          form.setValue(fieldName, fieldValue);
        },
      );

      setFileList(dataDetails?.feedback_image);
      const products = convertDataTableProduct(
        dataDetails?.order_detail?.filter((item) => item?.quantity_check),
      );
      setDataSource(products);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (dataDetailOrder && !id) {
      const data = {
        order_id: dataDetailOrder?.id,
        investor: dataDetailOrder?.investor_name || 'Hệ thống',
        type: dataDetailOrder?.type,
        device: dataDetailOrder?.device_name || '',
        invest: dataDetailOrder?.invest_name || '',
        ticket_type: dataDetailOrder?.ticket_type,
      };
      form.reset(data);
    }
  }, [dataDetailOrder, id]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  useEffect(() => {
    if (dataDetails) {
      const isEqual = arraysAreEqual(
        convertDataTableProduct(
          dataDetails?.order_detail?.filter((item) => item?.quantity_check),
        ),
        dataSource,
      );
      const isEqualImages = arraysAreEqual(
        dataDetails?.feedback_image,
        fileList,
      );

      if (!isEqual || !isEqualImages) setDirty(true);
      else setDirty(false);
    }
  }, [dataSource, dataDetails, fileList]);

  usePrompt(
    <>
      <p>{messageWarning.WARNING_EXIT_1}</p>
      <p>{messageWarning.WARNING_EXIT_2}</p>
    </>,
    isDirty || dirty,
  );

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            <Button
              className="page-header-v2-btn"
              type="success"
              prefix={<BookmarkOutlined />}
              onClick={form.handleSubmit(handleSubmitForm)}
              disabled={isError || (isLoading && !!id) || isLoadingMutate}
            >
              Lưu
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        <div className="page-content-v2">
          {isError && !!id ? (
            <NoData />
          ) : (
            <TabForm menu={TABS_FORM}>
              <FormTera form={form}>
                <CardFormV2
                  title="Thông tin chung"
                  id="information"
                  className="form-section"
                >
                  <Row className="grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0">
                    <FormTeraItem
                      label="Mã đơn hàng"
                      name="order_id"
                      rules={[
                        {
                          required: messageValidate.emptySelect,
                        },
                      ]}
                    >
                      <SelectOrderInspection
                        paramsApi={{
                          include_id: order_id,
                          status: ORDER_STATUS_KEY.approved,
                          is_order_return: id ? undefined : true,
                        }}
                        allowClear={false}
                        disabled={!!id}
                      />
                    </FormTeraItem>

                    <FormTeraItem label="Đầu tư" name="invest">
                      <Input disabled />
                    </FormTeraItem>

                    <FormTeraItem label="Danh mục đầu tư" name="type">
                      <SelectInvestmentCategory disabled />
                    </FormTeraItem>

                    <FormTeraItem label="Nhà đầu tư" name="investor">
                      <Input disabled />
                    </FormTeraItem>

                    <FormTeraItem label="Thiết bị" name="device">
                      <Input disabled />
                    </FormTeraItem>

                    <FormTeraItem label="Loại phiếu" name="ticket_type">
                      <SelectTicketType disabled />
                    </FormTeraItem>
                    {ticket_type !== 'request' && (
                      <FormTeraItem
                        label="Ghi chú"
                        name="note"
                        className="col-span-3"
                        rules={[
                          {
                            maxLength: {
                              value: 1000,
                              message: messageValidateLength.textArea,
                            },
                          },
                        ]}
                      >
                        <TextArea rows={5} />
                      </FormTeraItem>
                    )}
                  </Row>
                </CardFormV2>
                <CardFormV2
                  title="Sản phẩm"
                  id="product"
                  className="form-section"
                >
                  <div className="overflow-hidden bg-white shadow-xsm rounded-[5px]">
                    <TableTera
                      actionRef={tableRef}
                      mode="editable-cell"
                      columns={columns}
                      data={dataSource}
                      pagination={{}}
                      editable={{
                        onValuesChange: (_, list) => {
                          const newList = list
                            ?.filter((item) => !item?.isDelete)
                            ?.map((item) => ({
                              ...item,
                              total: item?.quantity_order * item?.amount,
                              remaining_quantity: item?.remaining_quantity || 0,
                            }));
                          setDataSource(newList);
                        },
                      }}
                      hiddenColumns={
                        ticket_type === 'request' && [
                          'abbreviation',
                          'catalog_name',
                          'unit_name',
                        ]
                      }
                    />
                    <div className="ml-auto max-w-max p-2.5 text-base">
                      <span className="text-gray-800">Tổng thành tiền: </span>
                      <span className="text-green-500 font-medium">
                        {formatCurrency(total)}
                      </span>
                    </div>
                  </div>
                </CardFormV2>

                {ticket_type !== 'request' && (
                  <CardFormV2
                    title="Hình ảnh trả hàng"
                    id="images"
                    className="form-section"
                  >
                    <div className="flex gap-2.5 flex-wrap">
                      {fileList.map((image, index) => (
                        <div className="relative" key={index}>
                          <XCircleSolid
                            className="cursor-pointer absolute z-50 w-6 right-[-10px] top-[-8px] fill-red-500"
                            onClick={() => handleRemoveFile(image)}
                          />
                          <Image
                            src={image}
                            alt={image}
                            borderRadius={10}
                            containerClassName="h-[100px] w-[100px] object-cover rounded-md border overflow-hidden"
                            imageClassName="object-cover"
                          />
                        </div>
                      ))}
                      <UploadFiles
                        max={50}
                        fileList={[]}
                        onReceiveFiles={(file) =>
                          setFileList((prev) => [...prev, file.url])
                        }
                        maxSize={5}
                        folder="sale-order-return"
                        object_key="sale-order-return"
                        isView={false}
                        isCount={false}
                        accept=".png, .jpeg, .gif, .jpg"
                      >
                        <button
                          className="w-[100px] h-[100px] border border-dashed border-gray-200 bg-gray-50 rounded-[10px] flex items-center justify-center"
                          type="button"
                        >
                          <PlusOutlined className="w-8 h-8 text-green-500" />
                        </button>
                      </UploadFiles>
                    </div>
                  </CardFormV2>
                )}
              </FormTera>
            </TabForm>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default SaleOrderReturnForm;
